import SmallSideBarItem from "./SmallSideBarItem/SmallSideBarItem";

import { RiFile3Line, RiGroupLine, RiHome2Line } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
import { FaHandHoldingMedical, FaStarOfLife } from "react-icons/fa";
import { MdOutlineCategory, MdCleaningServices } from "react-icons/md";
import { AiOutlineCustomerService, AiOutlineSolution } from "react-icons/ai";
import { BiMessageSquareDetail } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import "./SideBar.css";

function SideBar() {
  return (
    <>
      <section className="small_sidebar">
        <div className="container">
          <SmallSideBarItem link="/" dataTip="Home Page" dataFor="dashboard">
            <RiHome2Line />
          </SmallSideBarItem>
          <SmallSideBarItem
            link="/aboutScreen"
            dataTip="aboutScreen"
            dataFor="aboutScreen"
          >
            <FaUsers />
          </SmallSideBarItem>
          <SmallSideBarItem
            link="/products"
            dataTip="Products"
            dataFor="Products"
          >
            <FaHandHoldingMedical />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/solutionscreen"
            dataTip="Solution Screen"
            dataFor="Solution Screen"
          >
            <AiOutlineSolution />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/service"
            dataTip="Service Screen"
            dataFor="Service Screen"
          >
            <AiOutlineCustomerService />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/supplier"
            dataTip="supplier Screen"
            dataFor="supplier Screen"
          >
            <MdCleaningServices />
          </SmallSideBarItem>
          <SmallSideBarItem
            link="/resources_screen"
            dataTip="Resources Screen"
            dataFor="Resources Screen"
          >
            <RiFile3Line />
          </SmallSideBarItem>
          <SmallSideBarItem
            link="/careerScreen"
            dataTip="careerScreen"
            dataFor="careerScreen"
          >
            <FaStarOfLife />
          </SmallSideBarItem>

          <SmallSideBarItem
            link="/formResponses"
            dataTip="Contact"
            dataFor="Contact"
          >
            <BiMessageSquareDetail />
          </SmallSideBarItem>
          <SmallSideBarItem link="/usersScreen" dataTip="Users" dataFor="Users">
            <RiGroupLine />
          </SmallSideBarItem>


          <SmallSideBarItem
            link="/category"
            dataTip="category"
            dataFor="category"
          >
            <MdOutlineCategory />
          </SmallSideBarItem>











          <SmallSideBarItem
            link="/settings"
            dataTip="Settings"
            dataFor="Settings"
          >
            <IoSettingsOutline />
          </SmallSideBarItem>
        </div>
      </section>
    </>
  );
}

export default SideBar;
