import React, { useEffect, useState } from "react";
import { TESTIMONIAL_API } from "../../../../Utilities/APIs";

import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function UpdateTestimonial({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [designation, setDesignation] = useState("");
  const [image, setImage] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetOurSolutionInfo() {
      const { data } = await axiosInstance.get(TESTIMONIAL_API + targetID);
      setName(data.name);
      setDescription(data.description);
      setDesignation(data.designation)
      setImage(data.image);
    }
    fetchAndSetOurSolutionInfo();
  }, [targetID]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("name", name);
    itemData.append("description", description);
    itemData.append("designation", designation);
    itemData.append("image", image);

    const response = await axiosInstance.patch(
      TESTIMONIAL_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />
      <ShortTextInput
        label={`Designation`}
        value={designation}
        placeholder={`Enter Designation`}
        setState={setDesignation}
      />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Upload Image
      </ImageInput>

      <FormSubmitButton text="Update Our Solution " />
    </Form>
  );
}

export default UpdateTestimonial;
