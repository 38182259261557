import React, { useEffect, useState } from 'react'
import Modal from '../../../Partials/Elements/Modal/Modal'
import DeleteButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton'
import EditButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton'
import ViewButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton'
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell'
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow'
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth'
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader'
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable'
import CreateButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton'
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance'
import { ShowSections_API } from '../../../../Utilities/APIs'
import CreateSection from './CreateSection'
import UpdateSection from './UpdateSection'
import CRUDDeleteBoilerPlate from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate'

const ManageSection = () => {
    const [manager, setManager] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [targetID, setTargetID] = useState(null);
    const [showViewSection, setShowViewSection] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showDeleteSection, setShowDeleteSection] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false);
    const axiosInstance = useAxiosInstance();
  
    useEffect(() => {
      async function fetchAndSetProducts() {
        const { data } = await axiosInstance.get(ShowSections_API);
        setManager(data);
      }
      fetchAndSetProducts();
    }, [toggleFetch]);
  
    function handleClose() {
      setShowModal(false);
      setShowCreateForm(false);
      setShowViewSection(false);
      setShowUpdateForm(false);
      setShowDeleteSection(false);
    }
  
    function triggerFetch() {
      setToggleFetch((prevState) => !prevState);
    }
  
    console.log({manager})
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`Sections (${manager?.length} in total)`}</h1>
          <CreateButton
            screenTopicSingular="Section"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Visibility" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {manager?.map((product) => (
              <CRUDTableRow key={product._id}>
                <ShortTextCell text={product.sectionName} />
                <ShortTextCell text={`${product.isVisible }`} />
                {/* <ImageCell imgSrc={product.image} /> */}

                <td className="action_button_cell">
                  {/* <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={product._id}
                    setTargetID={setTargetID}
                  /> */}
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={product._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={product._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Section`) ||
            (showUpdateForm && `Update Section `) ||
            (showDeleteSection && `Delete Section`)
          }
        >
          {showCreateForm && (
            <CreateSection
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateSection
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <CRUDDeleteBoilerPlate
              api={ShowSections_API}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {/* {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Hero Slider`}
        >
          <ViewOurSolution targetID={targetID} />
        </Modal>
      )} */}
    </>
  )
}

export default ManageSection