import React from "react";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { RiImageLine } from "react-icons/ri";
import { SiAboutdotme } from "react-icons/si";
import { RiTeamFill } from "react-icons/ri";
import { MdManageAccounts } from "react-icons/md";
import { FaAward } from "react-icons/fa";
import { RiMessage2Fill } from "react-icons/ri";
import { FaBlog } from "react-icons/fa";
function SolutionScreen() {
  return (
    <NavCardList numOfCards={"eight"}>
      <NavCard cardName={"Solutions"} navCardLink={"/solutions"}>
        <SiAboutdotme />
      </NavCard>
      <NavCard cardName={"Testimonials"} navCardLink={"/testimonial"}>
        <MdManageAccounts />
      </NavCard>
      
    </NavCardList>
  );
}

export default SolutionScreen;
