import React from "react";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { FaBlog, FaBook } from "react-icons/fa";
import { RiImageLine, RiMessage2Fill } from "react-icons/ri";

function ResourcesScreen() {
  return (
    <NavCardList numOfCards={"eight"}>
      <NavCard cardName={"Case Studies"} navCardLink={"/caseStudies"}>
        <FaBook />
      </NavCard>
      <NavCard cardName={"Gallery"} navCardLink={"/gallery"}>
        <RiImageLine />
      </NavCard>
      <NavCard cardName={"News"} navCardLink={"/news"}>
        <RiMessage2Fill />
      </NavCard>
      <NavCard cardName={"Blogs"} navCardLink={"/blogs"}>
        <FaBlog />
      </NavCard> 
    </NavCardList>
  );
}

export default ResourcesScreen;
