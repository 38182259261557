import { useState, useEffect } from "react";

// import { CLIENTS_API, CONTENT_GENRES_API } from "../../../Utilities/APIs"
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";

import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";

import Modal from "../../Partials/Elements/Modal/Modal";
import { CONTACTS_API } from "../../../Utilities/APIs";
import CRUDDeleteBoilerPlate from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate";
import ViewContact from "./ViewContact";
import DeleteButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function Contacts() {
  const [contacts, setContacts] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetContactInfos() {
      const { data } = await axiosInstance.get(CONTACTS_API);
      setContacts(data);
    }
    fetchAndSetContactInfos();
  }, [toggleFetch, axiosInstance]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`Form Responses (${contacts?.length} in total)`}</h1>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Product Name" />
            <CRUDth th="Contact Type" />
            <CRUDth th="Email" />

            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {contacts?.map((contact) => (
              <CRUDTableRow key={contact._id}>
                <ShortTextCell text={contact.name} />
                <ShortTextCell text={contact.productName} />
                <ShortTextCell text={contact.contactType} />
                <ShortTextCell text={contact.email} />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={contact._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={contact._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create contact info`) ||
            (showUpdateForm && `Update contact info`) ||
            (showDeleteSection && `Delete contact info`)
          }
        >
          {/* {
                        showCreateForm &&
                        <CreateContactInfos
                            
                            setShowCreateForm={setShowCreateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    } */}
          {/* {
                        showUpdateForm &&
                        <UpdateContactInfo
                            
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                            triggerFetch={triggerFetch}
                        />
                    } */}
          {showDeleteSection && (
            <CRUDDeleteBoilerPlate
              api={CONTACTS_API}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Contact Info`}
        >
          <ViewContact targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default Contacts;
