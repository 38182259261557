import React, { useEffect, useState } from "react";
import { IMAGE_URL, ServicePage_API } from "../../../Utilities/APIs";
import Image from "../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function ViewService({ targetID }) {
  const [serviceInfo, setServiceInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetServiceInfo() {
      const { data } = await axiosInstance.get(ServicePage_API + targetID);
      setServiceInfo(data);
    }
    fetchAndSetServiceInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {serviceInfo && (
        <>
          <h1>Service Name</h1>
          <p>{serviceInfo.serviceName}</p>

          <h1>Detail</h1>
          <div
            dangerouslySetInnerHTML={{ __html: serviceInfo?.detail }}
          />

          <h1>Image</h1>
          <Image imgLink={serviceInfo.image} imgAlt="Service Image" />
        </>
      )}
    </div>
  );
}

export default ViewService;
